import React from 'react';

function About() {
  return (
    <div className='flex flex-col '>
      <p className='text-md sm:text-lg'>
        I'm a software developer at on a mission to create visually stunning and
        functionally impeccable user interfaces. With a keen eye for design and
        a love for coding, I specialize in mobile app development.
      </p>
    </div>
  );
}

export default About;
