import React from 'react';

export interface ICardProps {
  date: string;
  title: string;
  company: string;
  description: string;
}

function Card({ date, title, company, description }: ICardProps) {
  return (
    <div className='flex flex-col gap-1'>
      <h2 className='text-sm sm:text-lg'>{company}</h2>
      <h2 className='text-sm sm:text-[16px] text-gray-500'>
        {title} | {date}
      </h2>
      <h2 className='text-[14px] sm:text-[16px]'>{description}</h2>
    </div>
  );
}

export default Card;
