import React from 'react';
import Card from '../Card';

function CardList() {
  return (
    <div className='flex flex-col gap-4'>
      <Card
        date='November 2022-Present'
        title='Software Developer'
        company='NTT Data Business Solutions'
        description='Worked on various mobile application projects, focusing on performance optimization and ensuring seamless user interactions'
      />
      <Card
        date='June-September 2022'
        title='Email Development Specialist'
        company='Ministry of CRM'
        description='Developed responsive email templates and contributed to digital marketing projects using CMS and CRM platforms.'
      />
    </div>
  );
}

export default CardList;
