function Footer() {
  return (
    <footer className='grid p-4 gap-2 border-t-1 border-gray-300 '>
      <p className=' text-center text-[10px] sm:text-sm'>All coded by ✋🏻</p>
      <p className=' text-center text-[10px] sm:text-sm'>
        The website utilizes React and Tailwind for development and is hosted on
        Firebase.
      </p>
    </footer>
  );
}

export default Footer;
