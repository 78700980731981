import React from 'react';

function Writings() {
  // Kart verilerini dizi olarak tanımladık
  const writings = [
    {
      title: 'First Look at TypeScript',
      date: 'December, 2023',
      link: 'https://medium.com/@helinokcu/first-look-at-typescript-89e389b0fc0f',
    },
    {
      title: 'Firebase Analytics with Expo: Events',
      date: 'January, 2024',
      link: 'https://medium.com/@helinokcu/firebase-analytics-with-expo-events-38a1f6e4555f',
    },
  ];

  return (
    <div className='flex flex-col gap-4'>
      <h2 className='text-md sm:text-xl  font-medium'>Writings</h2>
      <div className='flex gap-6'>
        {writings.map((writing, index) => (
          <div
            key={index}
            className='border border-gray-500 p-4 transition ease-in-out delay-100 hover:-translate-y-1 hover:scale-110 
            duration-300 rounded-lg shadow-md hover:shadow-lg 
            text-black w-1/2 flex flex-col justify-between h-32 sm:h-24'
          >
            <a
              href={writing.link}
              target='_blank'
              rel='noopener noreferrer'
              className='h-full flex flex-col justify-between'
            >
              <p className='text-sm md:text-md '>{writing.title}</p>
              <p className='text-xs md:text-sm '>{writing.date}</p>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Writings;
