function Header() {
  return (
    <div className='flex w-5/6 lg:w-1/2 xl:w-1/2 2xl:w-1/2 mx-auto flex-row justify-between gap-12 pt-6 pb-6 items-center'>
      <div>
        <p className='text-sm sm:text-[16px]'>Helin Okcu</p>
      </div>
      <div className='flex gap-2 sm:gap-4 items-end justify-end'>
        <a href='https://www.linkedin.com/in/helinokcu/'>
          <p className='text-sm sm:text-[16px] hover:text-gray-200 '>
            LinkedIn{' '}
          </p>
        </a>
        <a href='https://medium.com/@helinokcu'>
          <p className='text-sm sm:text-[16px] hover:text-gray-200 '>Medium</p>
        </a>
        <a href='https://github.com/xhokcu'>
          <p className='text-sm sm:text-[16px] hover:text-gray-200 '>Github</p>
        </a>
      </div>
    </div>
  );
}

export default Header;
