import React from 'react';
import profile from './me.png';

function Profile() {
  return (
    <div className='flex flex-col sm:flex-row items-center gap-6'>
      <div className='flex'>
        <img alt='profilepic' className='w-16' src={profile} />
      </div>
      <div className=' flex flex-col gap-2'>
        <p className='text-[24px] sm:text-3xl'>Hi, I'm Helin.</p>
      </div>
    </div>
  );
}

export default Profile;
