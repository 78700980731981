import React from 'react';
import CardList from '../CardList';

function Experience() {
  return (
    <div className='flex flex-col gap-4'>
      <h2 className='text-md sm:text-xl font-medium '>Experience</h2>
      <CardList />
    </div>
  );
}

export default Experience;
